import { computedAsync } from '@vueuse/core';
import axios from 'axios';
import { Ref, ref } from 'vue';

// https://vueuse.org/core/computedasync/#usage

interface ComputedAsyncStateOptions {
  /**
   * Execute the promise right after the function is invoked.
   * When set to false, you will need to execute it manually.
   *
   * @default true
   */
  immediate?: boolean;

  /**
   * Choose to watch errors instead of throwing them.
   *
   * @default 'throw'
   */
  watchOrThrow?: 'throw' | 'watch';
}

export function useComputedAsyncState<T>(
  request: (signal: AbortSignal) => Promise<T> | T,
  initialState: T,
  options: ComputedAsyncStateOptions = {}
): {
  loading: Ref<boolean>;
  state: Ref<T>;
  error: Ref<unknown>;
} {
  const { immediate = true, watchOrThrow = 'throw' } = options;

  // Make a ref to track if the async function is evaluating
  const loading = ref(false);
  const error = ref<unknown | undefined>();

  // When the computed source changes before the previous async function gets resolved,
  // you may want to cancel the previous one.
  const state = computedAsync(
    (onCancel) => {
      const controller = new AbortController();

      onCancel(() => controller.abort());

      return request(controller.signal);
    },
    initialState,
    {
      evaluating: loading,
      lazy: !immediate,
      onError: (e) => {
        if (!axios.isCancel(e)) {
          if (watchOrThrow === 'throw') {
            throw e;
          } else {
            error.value = e;
          }
        }
      },
    }
  );

  return {
    state,
    loading,
    error,
  };
}
