<template>
  <div v-if="companySurcharges.length > 0" class="px-6 pb-5 pt-1 text-sm/4">
    <h3 class="mb-1 font-medium">
      <div v-if="isTrader" class="flex items-center">
        <span>{{ $t('surcharges.title-trader', { id: sellerId }) }}</span>
        <div class="mx-1 size-1 rounded-full bg-vesper-neutral"></div>
        <span>{{ $t('surcharges.potential-surcharges') }}</span>
      </div>
      <div v-else class="flex items-center">
        <span>{{ $t('surcharges.title', { id: sellerId }) }}</span>
        <div class="mx-1 size-1 rounded-full bg-vesper-neutral"></div>
        <span>{{ $t('surcharges.potential-surcharges') }}</span>
      </div>
    </h3>
    <p class="mb-5 text-vesper-neutral/50">
      {{ $t('surcharges.sub-title') }}
    </p>
    <div class="flex flex-col space-y-5 rounded-xl bg-slate-50 p-4">
      <div
        v-for="(surcharge, index) in companySurcharges"
        :key="surcharge.name"
        class="flex flex-col"
      >
        <div :class="{ 'border-t pt-3': index !== 0 }">
          <h4 class="mb-3 font-medium">
            {{ $t(`surcharges.${surcharge.name}`) }}
          </h4>
          <div class="grid grid-cols-3">
            <div
              v-for="option in surcharge.options"
              :key="option.name"
              class="flex flex-col text-sm/4"
            >
              <span class="mb-2 text-vesper-neutral/60">{{
                $t(`surcharges.${option.name}`)
              }}</span>
              <span class="text-vesper-neutral">
                {{ getPriceDisplay(option) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useApi } from '@/api';
import { useComputedAsyncState } from '@/hooks';
import { useProductStore } from '@/stores';
import { format } from '@/translations';
import { CompanySurchargeOption, PriceMultiplier } from '@/types/company';
import { t } from 'i18next';

const props = defineProps({
  sellerId: {
    type: Number,
    required: true,
  },
  isTrader: {
    type: Boolean,
    default: false,
  },
});

const api = useApi();
const productStore = useProductStore();

const { state: companySurcharges } = useComputedAsyncState(() => {
  return api.company.listProductSurcharges(
    props.sellerId,
    productStore.product.id
  );
}, []);

const getPriceMultiplierSuffix = (priceMultiplier: PriceMultiplier) => {
  switch (priceMultiplier) {
    case 'PRICE_PER_UNIT':
      return '/MT';
    default:
      return '';
  }
};

const getPriceDisplay = (option: CompanySurchargeOption) => {
  switch (option.priceMultiplier) {
    case 'FREE_OF_CHARGE':
    case 'UNDER_CONSULTATION':
      return t(`surcharges.${option.priceMultiplier}`);
    default:
      return `${format(Number(option.minPriceValue), 'money')}${
        option.maxPriceValue ? `–${format(option.maxPriceValue, 'number')}` : ''
      }${getPriceMultiplierSuffix(option.priceMultiplier)}`;
  }
};
</script>
