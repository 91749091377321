import { Api } from '@/api/types';
import { CompanyFactory, CompanyProductSurcharge } from '@/types/company';

export class Company {
  public constructor(protected readonly client: Api) {}

  public listProductSurcharges(
    companyId: number,
    productId: number
  ): Promise<CompanyProductSurcharge[]> {
    return this.client.get(
      `customer/${companyId}/products/${productId}/surcharges`
    );
  }

  public listFactories(): Promise<CompanyFactory[]> {
    return this.client.get('customer/factories');
  }

  public listApprovedFactories(): Promise<CompanyFactory[]> {
    return this.client.get('customer/approved-factories');
  }
}
