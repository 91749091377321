import fromApprovedFactories from '@/filters/listing/from-approved-factories';
import fromDesiredFactories from '@/filters/listing/from-desired-factories';
import { ListingPreview } from '@/types';

export default (listing: ListingPreview) => {
  switch (listing.type) {
    case 'BID':
      return fromDesiredFactories(listing);
    case 'OFFER':
      return fromApprovedFactories(listing);
    default:
      throw new Error(`Unknown listing type specified: ${listing.type}`);
  }
};
