import isOffer from '@/filters/listing/is-offer';
import { useUserStore } from '@/stores';
import { ListingPreview } from '@/types';

export default (listing: ListingPreview) => {
  if (useUserStore().user?.company.type != 'TRADER') {
    return true;
  }

  return isOffer(listing);
};
